import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useNavigate } from "react-router-dom";

const ManualBookings = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        {routes?.map((route, index) => (
          <Grid item xs={12} md={6} key={route?.id}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "280px",
                width: "100%",
                border: "dashed 1px rgb(62, 126, 236)",
                cursor: "pointer",
              }}
              onClick={() => navigate(route?.route)}
            >
              {route?.name}
            </div>
          </Grid>
        ))}
      </Grid>
    </DashboardLayout>
  );
};

export default ManualBookings;

const routes = [
  {
    id: "manual-hotel-bookings",
    name: "Hotel Bookings",
    route: "/manual-bookings/hotel-bookings-orders",
  },
  {
    id: "manual-visa-orders",
    name: "Visa Orders",
    route: "/manual-bookings/visa-orders",
  },
  {
    id: "manual-transportation-orders",
    name: "Transportation Orders",
    route: "/manual-bookings/transportation-orders",
  },
  {
    id: "manual-flight-orders",
    name: "Flight Orders",
    route: "/manual-bookings/flight-orders",
  },
];
