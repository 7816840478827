import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Done, GetApp } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import countries from "../../../helpers/countries.json";
import currencies from "../../../helpers/currency.json";
import AdapterMoment from "@date-io/moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import axiosInstance from "helpers/axiosInstance";

const PAYMENTS = [
  { label: "Stripe", id: 1 },
  { label: "PayBy", id: 2 },
  { label: "Tabby", id: 3 },
  { label: "Bank Transfer", id: 4 },
  { label: "Other", id: 5 },
];

const FormFirstSection = ({ formData, handleChangeFormDataField, setFormData }) => {
  return (
    <Box>
      <Typography sx={{ mt: 2, mb: 1 }}>Add Customer</Typography>
      {/* <Typography variant="h5">First Section</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="full_name">
            Full name
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="full_name"
            value={formData?.full_name}
            fullWidth
            id="full_name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="email">
            E-mail
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="email"
            value={formData?.email}
            fullWidth
            id="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="mobile">
            Mobile
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="mobile"
            value={formData?.mobile}
            fullWidth
            id="mobile"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="nationality">
            Nationality
          </label>
          <Autocomplete
            disablePortal
            value={formData?.nationality}
            options={countries}
            fullWidth
            onChange={(e, val) => setFormData((prev) => ({ ...prev, nationality: val?.label }))}
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSecondSection = ({
  formData,
  handleChangeFormDataField,
  setFormData,
  handleChangeFormDataPayment,
}) => {
  return (
    <Box>
      <Typography sx={{ mt: 2, mb: 1 }}>Booking Details</Typography>
      {/* <Typography variant="h5">First Section</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="booking_id">
            Booking ID
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="booking_id"
            value={formData?.booking_id}
            fullWidth
            id="booking_id"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="booked_through">
            Booked Through
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="booked_through"
            value={formData?.booked_through}
            fullWidth
            id="booked_through"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="hotel_name">
            Hotel Name
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="hotel_name"
            value={formData?.hotel_name}
            fullWidth
            id="hotel_name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="city">
            City
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="city"
            value={formData?.city}
            fullWidth
            id="city"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="booked_through">
            Booked through
          </label>
          <Autocomplete
            value={formData?.supplier_name}
            disablePortal
            options={[{ label: "RateHawk" }, { label: "PartoCRS" }, { label: "Other" }]}
            fullWidth
            onChange={(e, val) =>
              setFormData((prev) => ({
                ...prev,
                booked_through: val?.label,
              }))
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="supplier_price">
            Supplier Price
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="supplier_price"
            value={formData?.supplier_price}
            fullWidth
            type="number"
            id="supplier_price"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="supplier_currency">
            Supplier Currency
          </label>
          <Autocomplete
            value={formData?.supplier_currency}
            disablePortal
            options={currencies}
            fullWidth
            onChange={(e, val) =>
              setFormData((prev) => ({ ...prev, supplier_currency: val?.label }))
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="paid_amount">
            Paid Amount
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="paid_amount"
            value={formData?.paid_amount}
            fullWidth
            type="number"
            id="paid_amount"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="paid_currency">
            Paid Currency
          </label>
          <Autocomplete
            value={formData?.paid_currency}
            disablePortal
            options={currencies}
            fullWidth
            onChange={(e, val) => setFormData((prev) => ({ ...prev, paid_currency: val?.label }))}
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Typography style={{ fontSize: "14px" }} htmlFor="booked_on">
              Booking Date
            </Typography>
            <DatePicker
              value={formData?.booked_on}
              name="booked_on"
              renderInput={(props) => <TextField {...props} fullWidth />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFormData((prev) => ({ ...prev, booked_on: moment(date).format("YYYY-MM-DD") }));
              }}
              // label="Check-in"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Typography style={{ fontSize: "14px" }} htmlFor="checkin">
              Check-in
            </Typography>
            <DatePicker
              value={formData?.checkin}
              name="checkin"
              renderInput={(props) => <TextField {...props} fullWidth />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFormData((prev) => ({ ...prev, checkin: moment(date).format("YYYY-MM-DD") }));
              }}
              // label="Check-in"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Typography style={{ fontSize: "14px" }} htmlFor="booked_on">
              Check-out
            </Typography>
            <DatePicker
              value={formData?.checkout}
              name="checkout"
              renderInput={(props) => <TextField {...props} fullWidth />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFormData((prev) => ({ ...prev, checkout: moment(date).format("YYYY-MM-DD") }));
              }}
              // label="Check-in"
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="local_paid_via">
            Paid VIA
          </label>
          <Autocomplete
            value={formData?.local_paid_via}
            disablePortal
            options={[
              { label: "Stripe" },
              { label: "PayBy" },
              { label: "Tabby" },
              { label: "Bank Transfer" },
              { label: "Other" },
            ]}
            fullWidth
            onChange={(e, val) =>
              setFormData((prev) => ({
                ...prev,
                local_paid_via: val?.label,
                stripe_order_id: null,
                tabby_order_id: null,
                payby_order_number: null,
                payby_merchant_order_number: null,
              }))
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
          />
        </Grid>
        {formData?.local_paid_via === "Stripe" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="stripe_order_id">
              Stripe Payment ID
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="stripe_order_id"
              value={formData?.stripe_order_id}
              fullWidth
              id="stripe_order_id"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "Other" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="other_payment_method">
              Other
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="other_payment_method"
              value={formData?.other_payment_method}
              fullWidth
              id="other_payment_method"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "Tabby" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="tabby_order_id">
              Tabby Payment ID
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="tabby_order_id"
              value={formData?.tabby_order_id}
              fullWidth
              id="tabby_order_id"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "Bank Transfer" && (
          <Grid item xs={12} sm={6}>
            <label style={{ fontSize: "14px" }} htmlFor="bank_refrence_id">
              Transfer ID
            </label>
            <TextField
              onChange={handleChangeFormDataField}
              name="bank_refrence_id"
              value={formData?.bank_refrence_id}
              fullWidth
              id="bank_refrence_id"
            />
          </Grid>
        )}
        {formData?.local_paid_via === "PayBy" && (
          <>
            <Grid item xs={12} sm={6}>
              <label style={{ fontSize: "14px" }} htmlFor="payby_order_number">
                PayBy Order Number
              </label>
              <TextField
                onChange={handleChangeFormDataField}
                name="payby_order_number"
                value={formData?.payby_order_number}
                fullWidth
                id="payby_order_number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label style={{ fontSize: "14px" }} htmlFor="payby_merchant_order_number">
                PayBy Merchant Order Number
              </label>
              <TextField
                onChange={handleChangeFormDataField}
                name="payby_merchant_order_number"
                value={formData?.payby_merchant_order_number}
                fullWidth
                id="payby_merchant_order_number"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="notes">
            Notes
          </label>
          <TextField
            onChange={handleChangeFormDataField}
            name="notes"
            multiline
            rows={3}
            value={formData?.notes}
            fullWidth
            id="notes"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ProviderSection = ({ formData, handleChangeFormDataField, setFormData, handleNext }) => {
  const [loading, setLoading] = React.useState(false);

  const handleGetOrder = async () => {
    const dataSend = {
      ordering: {
        ordering_type: "asc",
        ordering_by: "checkin_at",
      },
      pagination: {
        page_size: "1",
        page_number: "1",
      },
      search: {
        order_ids: [formData?.rate_hawk_order_id],
      },
    };
    setLoading(true);

    try {
      const { data } = await axiosInstance?.post("/ratehawk/order-information", dataSend);
      const order = data?.data?.orders?.[0];

      const { data: dump } = await axiosInstance
        ?.post("/hotels/fetch", {
          hotel_id: order?.hotel_data?.id,
          language: "en",
        })
        .catch((err) => alert("could not get hotel dump"));

      setFormData((prev) => ({
        ...prev,
        full_name: `${order?.rooms_data?.[0]?.guest_data?.guests?.[0]?.first_name} ${order?.rooms_data?.[0]?.guest_data?.guests?.[0]?.last_name}`,
        rate_hawk_order_id: order?.order_id,
        booking_id: order?.order_id,
        booked_on: moment(order?.created_at).format(),
        booked_through: "RateHawk",
        checkin: moment(order?.checkin_at).format(),
        checkout: moment(order?.checkout_at).format(),
        hotel_name: dump?.hotels?.dump?.name,
        city: dump?.hotels?.dump?.region?.name,
        supplier_name: "RateHawk",
        supplier_price: parseFloat(order?.amount_sell_b2b2c?.amount),
        supplier_currency: order?.amount_sell_b2b2c?.currency_code,
      }));

      handleNext();
    } catch (error) {
      alert("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <label style={{ fontSize: "14px" }} htmlFor="local_paid_via">
          Provider
        </label>
        <Autocomplete
          value={formData?.supplier_name}
          disablePortal
          options={[{ label: "RateHawk" }, { label: "PartoCRS" }, { label: "Other" }]}
          fullWidth
          onChange={(e, val) =>
            setFormData((prev) => ({
              ...prev,
              supplier_name: val?.label,
            }))
          }
          renderInput={(params) => <TextField {...params} size="medium" />}
        />
      </Grid>
      {formData?.supplier_name === "RateHawk" && (
        <Grid item xs={12} sm={6}>
          <label style={{ fontSize: "14px" }} htmlFor="rate_hawk_order_id">
            RateHawk Order id
          </label>
          <TextField
            onChange={handleChangeFormDataField} // This is just for testing
            name="rate_hawk_order_id"
            value={formData?.rate_hawk_order_id}
            fullWidth
            id="rate_hawk_order_id"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Fetch Order"
                  onClick={handleChangeFormDataField}
                  // onMouseDown={handleMouseDownPassword}
                  // onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {loading ? <CircularProgress size={24} /> : <GetApp />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      )}
      {formData?.supplier_name === "RateHawk" && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "10px" }}>
          <Button variant="text" onClick={() => handleGetOrder()} size="large">
            Fetch Order
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default function ManualHotelBookingNew({ hide_view }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [formData, setFormData] = React.useState({
    booked_on: moment().format("YYYY-MM-DD"),
  });

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      console.log("isStepSkipped", activeStep);
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      try {
        const { data } = await axiosInstance.post("/manual-hotel", { ...formData, id: undefined });
      } catch (error) {
        alert("Something went wrong, please contact developer");
      } finally {
        hide_view();
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const FormThirdSection = (props) => {
    return (
      <Box>
        <Typography variant="h6">Third Section</Typography>
      </Box>
    );
  };

  const handleChangeFormDataField = React.useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const handleChangeFormDataFieldUknown = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
  };

  const steps = [
    {
      label: "Provider Details",
    },
    {
      label: "Customer Details",
    },
    {
      label: "Booking Details",
    },
  ];

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label?.label} {...stepProps}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {activeStep > index ? (
                  <Done fontSize="large" />
                ) : (
                  <Box
                    sx={{
                      width: "36px",
                      height: "36px",
                      background: activeStep >= index ? "#99f2c8" : "#FFF",
                      borderRadius: "50%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {index + 1}
                  </Box>
                )}
              </Box>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <Box
          sx={{
            padding: "10px",
            my: 2,
            background: "#fff",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          {activeStep === 0 ? (
            <ProviderSection
              formData={formData}
              handleChangeFormDataField={handleChangeFormDataField}
              setFormData={setFormData}
              handleNext={handleNext}
            />
          ) : activeStep === 1 ? (
            <FormFirstSection
              formData={formData}
              handleChangeFormDataField={handleChangeFormDataField}
              setFormData={setFormData}
            />
          ) : activeStep === 2 ? (
            <FormSecondSection
              formData={formData}
              handleChangeFormDataField={handleChangeFormDataField}
              setFormData={setFormData}
            />
          ) : (
            ""
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
