import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import ManualTransportationNew from "./transportation/ManualTransportationNew";
import axiosInstance from "helpers/axiosInstance";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import ManualTransportationUpdate from "./transportation/ManualTransportationUpdate";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Filters from "./transportation/Filters";

function ManualTransportationOrders() {
  const [showNewSection, setShowNewSection] = React.useState(false);
  const [showUpdateSection, setShowUpdateSection] = React.useState(false);
  const [updateData, setUpdateData] = React.useState({});

  const [transportationOrders, setTransportationOrders] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [count, setCount] = React.useState(null);

  React.useEffect(() => {
    fetchTransportationOrders();
  }, [page, pageSize]);

  const fetchTransportationOrders = async (filters = null) => {
    try {
      const { data } = await axiosInstance.post("/manual-transportation/fetch-all-crm", {
        page: page + 1,
        limit: pageSize,
        ...filters,
      });
      // const data = await response.json();
      setTransportationOrders(data?.manual_transportation_orders?.rows);
      setCount(data?.manual_transportation_orders?.count);
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewOrder = (order) => {
    setShowUpdateSection(false);

    setShowNewSection(false);
    setUpdateData(order);

    setTimeout(() => {
      setShowUpdateSection(true);
    }, 500);
    // setShowUpdateSection(true);
  };

  const getExpectedProfit = (order) => {
    let expected_profit = "N/A";

    if (
      order?.supplier_currency !== "AED" ||
      order?.paid_currency !== "AED" ||
      !order?.supplier_price ||
      !order?.paid_amount
    )
      return "N/A";

    if (order?.tabby_order_id) {
      let sevenPercent = (order?.paid_amount + 1.5) * (7 / 100);
      let otherPercent = (1.5 + (order?.paid_amount + 1.5) * (7 / 100)) * (5 / 100);

      let tax = 1.5 + sevenPercent + otherPercent;
      return order?.paid_amount - order?.supplier_price;
    } else if (order?.stripe_order_id) {
      let tax = order?.paid_amount * (3.9 / 100);
      expected_profit = order?.paid_amount - order?.supplier_price - tax;
    } else if (order?.payby_order_number || order?.payby_merchant_order_number) {
      let tax = order?.paid_amount * (2 / 100);
      expected_profit = order?.paid_amount - order?.supplier_price - tax;
    }

    return expected_profit || "N/A";
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (fileName, filters) => {
    if (!filters.from || !filters.to) {
      return alert("Please select from and to date");
    }

    const { data: dataApi } = await axiosInstance.post("/manual-transportation/fetch-all-crm", {
      page: page + 1,
      limit: count,
      ...filters,
    });

    const allOrders = dataApi?.manual_transportation_orders?.rows;

    // console.log(newApiData);
    const ws = XLSX.utils.json_to_sheet(allOrders?.filter((val) => val)); // convert JSON to worksheet
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }; // create a new workbook
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" }); // write the workbook
    const data = new Blob([excelBuffer], { type: fileType }); // create a blob of data
    FileSaver.saveAs(data, fileName + fileExtension); // save the blob as a file
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>
        <Box mt={4}></Box>
        <Filters exportCSV={exportToCSV} fetchTransportationOrders={fetchTransportationOrders} />
        <Box mt={4}></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            borderRadius: "5px",
            color: "#FFF",
            padding: "15px",
            background: "linear-gradient(195deg,rgb(92, 95, 99), #212121)",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Manual Transportation</h5>
          <Button
            variant="contained"
            color="success"
            style={{ color: "#212121" }}
            onClick={() => setShowNewSection(!showNewSection)}
          >
            {showNewSection ? "Close" : "Add New"}
          </Button>
        </Box>
        <Box>
          {showNewSection && <ManualTransportationNew hide_view={() => setShowNewSection(false)} />}
        </Box>
        <Box>
          {showUpdateSection && !showNewSection && (
            <ManualTransportationUpdate
              visa_data={updateData}
              hide_view={() => setShowUpdateSection(false)}
            />
          )}
        </Box>
        <Box mt={2}></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            borderRadius: "5px",
            color: "#FFF",
            padding: "15px",
            background: "linear-gradient(195deg,rgb(92, 95, 99), #212121)",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h5>Manual Transportation Orders</h5>
        </Box>
        <Box>
          <TableContainer sx={{ marginTop: "20px" }}>
            <Table>
              <TableHead sx={{ width: "100%", display: "table-header-group" }}>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Nationality</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Paid Amount</TableCell>
                  <TableCell>Expected Profit</TableCell>
                  <TableCell>Visa Status</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transportationOrders?.map((order) => (
                  <TableRow>
                    <TableCell>{order?.id}</TableCell>
                    <TableCell>{order?.full_name}</TableCell>
                    <TableCell>{order?.nationality}</TableCell>
                    <TableCell>{order?.from}</TableCell>
                    <TableCell>{order?.to}</TableCell>
                    <TableCell>{`${order?.paid_amount} ${order?.paid_currency}`}</TableCell>
                    <TableCell>{getExpectedProfit(order)}</TableCell>
                    <TableCell>{order?.status}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewOrder(order)}>
                        <RemoveRedEyeOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              sx={{ display: "flex" }}
              count={count}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={(e, page) => {
                setPage(page);
                setTransportationOrders([]);
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(e?.target?.value);
                setPage(0);
                setTransportationOrders([]);
              }}
            />
          </TableContainer>
        </Box>
      </MDBox>
      <MDBox p={2}>
        {/* <Box>{showUpdateSection && <ManualVisaNew data={UpdateData} />}</Box> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default ManualTransportationOrders;

const thStyle = {
  textAlign: "left",
  fontSize: "12px",
  border: "1px solid #212121",
  padding: "5px",
};

const tdStyle = {
  textAlign: "left",
  fontSize: "12px",
  border: "1px solid rgb(140 140 140)",
  padding: "5px",
};
