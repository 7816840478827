import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ordersTableData from "layouts/ratehawkOrders/data/ordersTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActivity } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { fetchAllBookings } from "redux/Actions/Bookings";
import { fetchAllOrders } from "redux/Actions/Orders";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import instance from "../../helpers/axiosInstance";
import Loader from "examples/Loader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DownloadingRounded, RestartAltRounded } from "@mui/icons-material";

function Booking() {
  const [orders, setOrders] = useState(null);
  const [ordersCount, setOrdersCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [filter, setFilter] = useState({
    email: null,
    status: "COMPLETED",
    rate_hawk_order_id: null,
    jd_checkin: null,
    jd_checkout: null,
    jd_guest_name: null,
    jd_hotel_name: null,
    jd_hotel_country: null,
    jd_hotel_city: null,
    jd_free_cancellation: "all",
    from: null,
    to: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!orders) {
      fetchOrders();
    }
  }, [orders]);

  const applyFilters = () => {
    setPage(0);
    fetchOrders();
  };

  const resetFilter = () => {
    setFilter((prev) => ({
      email: null,
      status: "COMPLETED",
      rate_hawk_order_id: null,
      jd_checkin: null,
      jd_checkout: null,
      jd_guest_name: null,
      jd_hotel_name: null,
      jd_hotel_country: null,
      jd_hotel_city: null,
      jd_free_cancellation: null,
      from: null,
      to: null,
    }));

    setOrders(null);
    setOrdersCount(null);
    setPage(0);
  };

  const fetchOrders = async (e) => {
    e?.preventDefault();

    try {
      setLoading(true);

      const { data } = await instance.post("/ratehawk-orders/fetch-crm", {
        ...filter,
        page: page + 1,
        pageSize,
      });

      setOrders(data?.orders?.rows);
      setOrdersCount(data?.orders?.count);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      alert("Something went wrong! Please refresh page or contact the developer");
    }
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (apiData, fileName) => {
    if (!apiData || apiData?.length === 0) return alert("No orders found");

    if (!filter.from || !filter.to) {
      return alert("Please select from and to date");
    }

    const currencies = await instance
      ?.post("/convert/excel", { to: "AED" })
      .catch(() => alert("Something went wrong"));

    if (!currencies?.data?.convert) return alert("Something went wrong");

    const allCurrencies = currencies?.data?.convert;

    let allOrders;

    // let arrayOfOrders = await fetchAllOrderDetails(
    //   apiData?.map((val) => val?.rate_hawk_order_id),
    //   apiData?.map((val) => val?.hotel_id)
    // );

    const { data: rows } = await instance.post("/ratehawk-orders/fetch-crm", {
      ...filter,
      page: 1,
      pageSize: ordersCount,
    });

    allOrders = rows?.orders?.rows;

    let newApiData = [...allOrders]?.map((order) => {
      // let obj = arrayOfOrders?.find((val) => val?.order_id === order?.rate_hawk_order_id);
      if (order?.hotel_id === "test_hotel" || order?.hotel_id === "test_hotel_do_not_book")
        return null;

      let data_finish = order?.data_finish ? JSON?.parse(order?.data_finish) : {};

      const full_recieved_amount =
        addCommision(order?.show_amount, order?.commission, true) -
        (order?.promo_discount_user_currency || 0);

      let payment_commission = 0;
      let payment_via;

      if (order?.stripe_uuid) {
        if (
          moment(order?.createdAt)?.isAfter(moment("2024-10-01")) &&
          order?.rate_hawk_order_id !== 546284906 &&
          order?.rate_hawk_order_id !== 974986576 &&
          order?.rate_hawk_order_id !== 363056703 &&
          order?.rate_hawk_order_id !== 248415958 &&
          order?.platform === "MOBILE"
        ) {
          payment_commission = 0.039;
        } else if (order?.currency === "AED") {
          payment_commission = 0.039;
        } else {
          payment_commission = 0.049;
        }
        payment_via = "Stripe";
      } else if (order?.ziina_uuid) {
        payment_commission = 0.04;
        payment_via = "Ziina";
      } else if (order?.jd_pb_order_number) {
        payment_commission = 0.02;
        payment_via = "PayBy";
      } else if (order?.wallet_transaction_id) {
        payment_via = "Wallet";
        payment_commission = 0.049;
      }

      const ziina_or_stripe_commission =
        full_recieved_amount - full_recieved_amount * payment_commission;

      const tax_amount = full_recieved_amount - ziina_or_stripe_commission;

      const expected_profit = full_recieved_amount - Number(order?.show_amount) - tax_amount;

      const currencyRateToAED = allCurrencies?.find((val) => val?.from === order?.currency);

      const paidInAED = full_recieved_amount * currencyRateToAED?.value;

      let localOrder = {
        "Booked On": order?.createdAt,
        "Check-in": order?.jd_checkin || "-",
        "Check-out": order?.jd_checkout || "-",
        "Customer Name": order?.jd_guest_name,
        Nationality: data_finish?.rooms ? data_finish?.rooms[0].guests[0].residency : "N/A",
        Paid: order?.is_paid,
        "Phone Number": data_finish?.user?.phone,
        "Hotel Name": order?.jd_hotel_name || "N/A",
        // "Partner order ID": order?.uuid,
        "RateHawk order id": order?.rate_hawk_order_id,
        Country: order?.country_full,
        State: order?.state,
        "Rate Hawk Amount": order?.amount,
        Currency: order?.ratehawk_currency,
        "Paid amount w/o commission": order?.show_amount,
        "Currency paid": order?.currency,
        Commission: `${order?.commission}%`,
        "Paid amount in AED": order?.currency === "AED" ? full_recieved_amount : paidInAED,
        "Paid by": payment_via,
        "Has Free Cancellation": order?.jd_free_cancellation,
        Status: order?.status,
        "Received amount": `${
          addCommision(order?.show_amount, order?.commission) - order?.promo_discount_user_currency
        } ${order?.currency}`,
        "Promo Code": order?.promo_code || "N/A",
        "Promo Code discount": order?.promo_discount_user_currency
          ? `${order?.promo_discount_user_currency} ${order?.currency}`
          : "N/A",
        "Possible net profit":
          order?.status === "CANCELLED" || order?.status === "REFUNDED" || order?.status === "PAID"
            ? 0
            : `${expected_profit.toFixed(2)} ${order?.currency}`,
        "Possible net AED":
          order?.status === "CANCELLED" || order?.status === "REFUNDED" || order?.status === "PAID"
            ? 0
            : order?.currency === "AED"
            ? expected_profit
            : expected_profit * currencyRateToAED?.value,
        // "RateHawk status": obj?.status,
      };

      return { ...localOrder };
    });

    // console.log(newApiData);
    const ws = XLSX.utils.json_to_sheet(newApiData?.filter((val) => val)); // convert JSON to worksheet
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }; // create a new workbook
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" }); // write the workbook
    const data = new Blob([excelBuffer], { type: fileType }); // create a blob of data
    FileSaver.saveAs(data, fileName + fileExtension); // save the blob as a file
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} component={Paper}>
        <Box
          my={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <TextField
            placeholder="Email"
            value={filter?.email}
            onChange={(e) => setFilter((prev) => ({ ...prev, email: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="Order ID"
            value={filter?.rate_hawk_order_id}
            onChange={(e) => setFilter((prev) => ({ ...prev, rate_hawk_order_id: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="Guest Name"
            value={filter?.jd_guest_name}
            onChange={(e) => setFilter((prev) => ({ ...prev, jd_guest_name: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="Hotel Name"
            value={filter?.jd_hotel_name}
            onChange={(e) => setFilter((prev) => ({ ...prev, jd_hotel_name: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="City"
            value={filter?.jd_hotel_city}
            onChange={(e) => setFilter((prev) => ({ ...prev, jd_hotel_city: e.target.value }))}
          ></TextField>
          <TextField
            placeholder="Country"
            value={filter?.jd_hotel_country}
            onChange={(e) => setFilter((prev) => ({ ...prev, jd_hotel_country: e.target.value }))}
          ></TextField>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.jd_checkin}
              name="Check-in"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, jd_checkin: moment(date).format("YYYY-MM-DD") }));
              }}
              label="Check-in"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.jd_checkout}
              name="Check-out"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, jd_checkout: moment(date).format("YYYY-MM-DD") }));
              }}
              label="Check-out"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.from}
              name="From"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, from: moment(date).format("YYYY-MM-DD") }));
              }}
              label="From"
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={filter?.to}
              name="To"
              renderInput={(props) => <TextField {...props} />}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => {
                setFilter((prev) => ({ ...prev, to: moment(date).format("YYYY-MM-DD") }));
              }}
              label="To"
            />
          </LocalizationProvider>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.status}
              label="Status"
              onChange={(e) => setFilter((prev) => ({ ...prev, status: e.target.value }))}
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={"COMPLETED"}>Completed</MenuItem>
              <MenuItem value={"CANCELLED"}>Cacncelled</MenuItem>
              <MenuItem value={"REFUNDED"}>Refunded</MenuItem>
              <MenuItem value={"FAILED_REFUNDED"}>Failed and refunded</MenuItem>
              <MenuItem value={"PENDING"}>Pending</MenuItem>
              <MenuItem value={"PAID"}>Paid</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="demo-simple-select-label">Free Cancellation</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter?.jd_free_cancellation}
              label="Free cancellation"
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, jd_free_cancellation: e.target.value }))
              }
              size="medium"
              sx={{ height: "44.13px", minWidth: "160px" }}
              variant="outlined"
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={true}>Free cancellation</MenuItem>
              <MenuItem value={false}>No free cancellation</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box mb={4} sx={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Button
            onClick={() => {
              applyFilters();
            }}
            sx={{ color: "white !important" }}
            variant="contained"
          >
            Apply Filters
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<RestartAltRounded />}
            sx={{ color: "white !important" }}
            onClick={() => {
              resetFilter();
            }}
          >
            Reset filters
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<DownloadingRounded />}
            sx={{ color: "white !important" }}
            onClick={() => {
              if (orders != null && orders?.length > 0) {
                return exportToCSV(orders, `${moment().format("YYYY-MM-DD")} report`);
              } else {
                return alert("Nothing to report");
              }
            }}
          >
            Export to excel
          </Button>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650, width: "100%" }} aria-label="Hotel Orders">
            <TableHead sx={{ background: "#F1F2F4", width: "100%", display: "table-header-group" }}>
              <TableRow sx={{ width: "100%" }}>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Guest</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Email</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Phone</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Hotel Name</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>RateHawk amount</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Commission</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Paid Amount</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Payment ID</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Payment Platform</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Status</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Platform</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Profit</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>User</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Data Finish</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Booking log</TableSortLabel>
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <TableSortLabel>Date</TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            {orders?.length > 0 ? (
              <TableBody>
                {orders?.map((order, index) => {
                  const full_recieved_amount = addCommision(
                    order?.show_amount,
                    order?.commission,
                    true
                  );

                  let payment_commission;
                  let payment_id;
                  let payment_via;

                  if (order?.stripe_uuid) {
                    if (
                      moment(order?.createdAt)?.isAfter(moment("2024-10-01")) &&
                      order?.rate_hawk_order_id !== 546284906 &&
                      order?.rate_hawk_order_id !== 974986576 &&
                      order?.rate_hawk_order_id !== 363056703 &&
                      order?.rate_hawk_order_id !== 248415958 &&
                      order?.platform === "MOBILE"
                    ) {
                      payment_commission = 0.039;
                    } else if (order?.currency === "AED") {
                      payment_commission = 0.039;
                    } else {
                      payment_commission = 0.049;
                    }
                    payment_id = order?.stripe_uuid;
                    payment_via = "Stripe";
                  } else if (order?.ziina_uuid) {
                    payment_commission = 0.04;
                    payment_id = order?.ziina_uuid;
                    payment_via = "Ziina";
                  } else if (order?.jd_pb_order_number) {
                    payment_commission = 0.02;
                    payment_id = order?.jd_pb_order_number;
                    payment_via = "Pay By";
                  } else if (order?.wallet_transaction_id) {
                    payment_commission = 0.049;
                    payment_id = `wallet-${order?.wallet_transaction_id}`;
                    payment_via = "Wallet";
                  }

                  const ziina_or_stripe_commission =
                    full_recieved_amount - full_recieved_amount * payment_commission;

                  const tax_amount = full_recieved_amount - ziina_or_stripe_commission;

                  const expected_profit =
                    full_recieved_amount -
                    Number(order?.show_amount) -
                    tax_amount -
                    (order?.promo_discount_user_currency || 0);

                  const dataFinish = JSON?.parse(order?.data_finish);

                  const phone_number = dataFinish?.user?.comment
                    ? dataFinish?.user?.phone
                    : dataFinish?.rooms?.[0]?.guests?.[0]?.phone_number;

                  // console.log(dataFinish);

                  return (
                    <TableRow key={index}>
                      <TableCell sx={tableCellStyle}>
                        {order?.rate_hawk_order_id || "N/A"}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{order?.jd_guest_name || "N/A"}</TableCell>
                      <TableCell sx={tableCellStyle}>{order?.email || "N/A"}</TableCell>
                      <TableCell sx={tableCellStyle}>{phone_number || "N/A"}</TableCell>
                      <TableCell sx={tableCellStyle}>{order?.jd_hotel_name || "N/A"}</TableCell>
                      <TableCell sx={tableCellStyle}>
                        {`${order?.show_amount} ${order?.currency}` || "N/A"}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{`${order?.commission}%` || "N/A"}</TableCell>
                      {/* <TableCell sx={tableCellStyle}>{`${full_recieved_amount || "-"} ${
                        order?.currency || ""
                      }`}</TableCell> */}
                      <TableCell sx={tableCellStyle}>
                        {order?.promo_discount_user_currency ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                padding: "0 5px",
                                textDecorationThickness: "3px",
                                textDecorationColor: "red" /* Change the color of the line */,
                              }}
                            >
                              {full_recieved_amount}
                            </span>
                            {`${
                              full_recieved_amount - (order?.promo_discount_user_currency || 0)
                            }  ${order?.currency}`}
                          </>
                        ) : (
                          <>{full_recieved_amount}</>
                        )}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>{payment_id}</TableCell>
                      <TableCell sx={tableCellStyle}>{payment_via}</TableCell>
                      <TableCell sx={tableCellStyle}>{order?.status || "N/A"}</TableCell>
                      <TableCell sx={tableCellStyle}>{order?.platform || "N/A"}</TableCell>
                      <TableCell sx={tableCellStyle}>{`${expected_profit?.toFixed(2) || "-"} ${
                        order?.currency || ""
                      }`}</TableCell>
                      <TableCell>
                        {order?.user?.username ? (
                          <Link
                            to={"/users/" + order?.user?.username}
                            target={"_blank"}
                            style={{ fontSize: "14px", color: "rgb(62, 137, 214)" }}
                          >
                            {order?.user?.username}
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>
                        {order?.data_finish ? (
                          <DataFinishComp data_finish={order?.data_finish} />
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>
                        {order?.booking_log ? (
                          <BookinglogComp booking_log={order?.booking_log} />
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell sx={tableCellStyle}>
                        {order?.createdAt
                          ? moment(order?.createdAt).format("DD/MM/YYYY hh:mm A")
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          sx={{ display: "flex" }}
          count={ordersCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
            setOrders(null);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(e.target.value);
            setPage(0);
            setOrders(null);
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Booking;

const DataFinishComp = ({ data_finish }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        Open
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <DataFinishContainer data_finish={data_finish} />
        </Box>
      </Modal>
    </>
  );
};

const BookinglogComp = ({ booking_log }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        Open
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <h1>Event Log</h1>
          <pre style={{ padding: "0 10px", fontSize: "16px" }}>{booking_log}</pre>
        </Box>
      </Modal>
    </>
  );
};
const DataFinishContainer = ({ data_finish }) => {
  const dataFinish = JSON?.parse(data_finish) || {};
  return <>{Object.entries(dataFinish).map(([key, value]) => value && renderRow(key, value))}</>;
};

const renderRow = (key, value) => {
  if (typeof value === "object" && !Array.isArray(value)) {
    return (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              {Object?.entries(value)?.map(
                ([nestedKey, nestedValue]) => nestedValue && renderRow(nestedKey, nestedValue)
              )}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  } else if (key === "rooms") {
    return (
      // <TableRow>
      <>
        {value?.map((room, index) => (
          <TableRow>
            <TableCell>Room {index + 1}</TableCell>
            <TableCell>
              <Table>
                <TableBody>
                  {room?.guests?.map((guest, index) => {
                    return Object?.entries(guest)?.map(
                      ([nestedKey, nestedValue]) => nestedValue && renderRow(nestedKey, nestedValue)
                    );
                  })}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        ))}
      </>
      // </TableRow>
    );
  } else {
    return (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>{value.toString()}</TableCell>
      </TableRow>
    );
  }
};

const tableCellStyle = {
  fontSize: "14px",
};
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const showAmountWithCommission = (amount, commission) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  return roundCommision(newAmount).toLocaleString();
};

export const roundCommision = (number) => {
  return Math.ceil(number);
};

export const addCommision = (amount, commission = 6, ciel = true) => {
  const rate = Number(commission) / 100;
  const newAmount = Number(amount) + Number(amount) * rate;
  if (ciel) {
    return roundCommision(newAmount);
  }
  return newAmount;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "60vw",

  maxHeight: "60vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
