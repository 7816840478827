/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Booking from "layouts/Bookings";
import RHOrders from "layouts/ratehawkOrders";
import RHOrders2 from "layouts/hotelOrders";
import EditBooking from "layouts/Bookings/Edit";
import Tables from "layouts/tables";
import Country from "layouts/countries";
import Activity from "layouts/activities";
import ActivityEdit from "layouts/activities/Edit";
import ActivityNew from "layouts/activities/New";
// import Billing from "layouts/billing";
import Userplans from "layouts/user-plans/Dashboard";
import Userplan from "layouts/user-plans/View";
import MainCategories from "layouts/main-categories/index";
import EditMainCategory from "layouts/main-categories/Edit";
import NewMainCategory from "layouts/main-categories/New";
import ActivityCategories from "layouts/activity-categories/index";
import EditActivityCategories from "layouts/activity-categories/Edit";
import NewActivityCategories from "layouts/activity-categories/New";
import Users from "layouts/users/Dashboard";
import UsersDashboard from "layouts/users/DashboardPage";
import EditUser from "layouts/users/Edit";
import NewUser from "layouts/users/New";
import Questionnaire from "layouts/questionnaire/index";
import EditQuestionnaire from "layouts/questionnaire/Edit";
import NewQuestionnaire from "layouts/questionnaire/New";
import Reports from "layouts/reports/index";
import Post from "layouts/post/index";
import Wallet from "layouts/wallet-transactions";
import Points from "layouts/points";
// promo code

import PromoCode from "layouts/promo-code";
import PromoCodeNew from "layouts/promo-code/New";
import PromoCodeEdit from "layouts/promo-code/Edit";
// promo code end
import RTL from "layouts/rtl";
import NewBooking from "layouts/Bookings/New";
// import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import EmailBooking from "layouts/email-booking";
import NewBookingEmail from "layouts/email-booking/booking";
import NewCancellationEmail from "layouts/email-booking/cancellation";
import ResendEmail from "layouts/email-booking/resend";
import SignUp from "layouts/authentication/sign-up";
import Sliders from "layouts/sliders";
import EditSlider from "layouts/sliders/Edit";
import NewSlider from "layouts/sliders/New";
// Manual Bookings
import ManualFlightBookings from "layouts/manual-bookings/manual-flight-bookings";
import ManualTransportaionOrders from "layouts/manual-bookings/manual-transportation-orders";
import ManualVisaOrders from "layouts/manual-bookings/manual-visa-orders";
import ManualHotelBookings from "layouts/manual-bookings/manual-booking-orders";
import ManualBookings from "layouts/manual-bookings";

// Airalo ESIM Orders
import EsimOrders from "layouts/airalo";
// @mui icons
import Icon from "@mui/material/Icon";
import ActivityDashboard from "layouts/activities/Dashboard";

export const allRoutesDefaults = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Countries",
    key: "countries",
    icon: <Icon fontSize="small">Public</Icon>,
    route: "/countries",
    component: <Country />,
  },
  {
    type: "collapse",
    name: "Country",
    key: "country",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/country/new",
    component: <Country />,
  },
  {
    type: "collapse",
    name: "Edit Activity",
    key: "activities",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/activities/:id",
    component: <ActivityEdit />,
  },
  {
    type: "collapse",
    name: "New Activity",
    key: "activities",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/activities/new",
    component: <ActivityNew />,
  },
  {
    type: "collapse",
    name: "Activities",
    key: "activities",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/activities",
    component: <ActivityDashboard />,
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/userplans",
    component: <Userplans />,
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/userplans/:id",
    component: <Userplan />,
  },
  {
    type: "collapse",
    name: "Main categories",
    key: "main_categories",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/main_categories",
    component: <MainCategories />,
  },
  {
    type: "collapse",
    name: "Main categories",
    key: "main_categories",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/main_categories/:id",
    component: <EditMainCategory />,
  },
  {
    type: "collapse",
    name: "Main categories",
    key: "main_categories",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/main_categories/new",
    component: <NewMainCategory />,
  },
  {
    type: "collapse",
    name: "Activity categories",
    key: "activity_categories",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/activity_categories",
    component: <ActivityCategories />,
  },
  {
    type: "collapse",
    name: "Activity categories",
    key: "activity_categories",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/activity_categories/new",
    component: <NewActivityCategories />,
  },
  {
    type: "collapse",
    name: "Activity categories",
    key: "activity_categories",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/activity_categories/:id",
    component: <EditActivityCategories />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/users/dashboard",
    component: <UsersDashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/users/:username",
    component: <EditUser />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/users/new",
    component: <NewUser />,
  },
  {
    type: "collapse",
    name: "Questionnaire",
    key: "questionnaire",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/questionnaire",
    component: <Questionnaire />,
  },
  {
    type: "collapse",
    name: "Questionnaire",
    key: "questionnaire",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/questionnaire/:id",
    component: <EditQuestionnaire />,
  },
  {
    type: "collapse",
    name: "Questionnaire",
    key: "questionnaire",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/questionnaire/new",
    component: <NewQuestionnaire />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">report</Icon>,
    route: "/reports",
    component: <Reports />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">report</Icon>,
    route: "/post/:id",
    component: <Post />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Booking",
    key: "booking",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/booking",
    component: <Booking />,
  },
  {
    type: "collapse",
    name: "Booking",
    key: "booking",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/booking/:id",
    component: <EditBooking />,
  },
  {
    type: "collapse",
    name: "Booking",
    key: "booking",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/booking/new",
    component: <NewBooking />,
  },
  {
    type: "collapse",
    name: "Jadwelny Orders",
    key: "jad-orders",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/jad-orders",
    component: <RHOrders />,
  },
  {
    type: "collapse",
    name: "All Orders",
    key: "all-orders",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/all-orders",
    component: <RHOrders2 />,
  },
  {
    type: "collapse",
    name: "Booking Emails",
    key: "emails-booking",
    icon: <Icon fontSize="small">mail</Icon>,
    route: "/emails-booking",
    component: <EmailBooking />,
  },
  {
    type: "collapse",
    name: "New Booking Email",
    key: "emails-booking",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/emails-booking/booking",
    component: <NewBookingEmail />,
  },
  {
    type: "collapse",
    name: "Cancellation Emails",
    key: "emails-booking",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/emails-booking/cancellation",
    component: <NewCancellationEmail />,
  },
  {
    type: "collapse",
    name: "Resend Email",
    key: "emails-booking",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/emails-booking/resend",
    component: <ResendEmail />,
  },
  {
    type: "collapse",
    name: "Wallet Transactions",
    key: "wallet-transactions",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/wallet-transactions",
    component: <Wallet />,
  },
  {
    type: "collapse",
    name: "Points Transactions",
    key: "points-transactions",
    icon: <Icon fontSize="small">attach_money</Icon>,
    route: "/points-transactions",
    component: <Points />,
  },
  {
    type: "collapse",
    name: "Promo Codes",
    key: "promo-code",
    icon: <Icon fontSize="small">discount</Icon>,
    route: "/promo-code",
    component: <PromoCode />,
  },
  {
    type: "collapse",
    name: "Promo Codes",
    key: "promo-code",
    icon: <Icon fontSize="small">discount</Icon>,
    route: "/promo-code/new",
    component: <PromoCodeNew />,
  },
  {
    type: "collapse",
    name: "Promo Codes",
    key: "promo-code",
    icon: <Icon fontSize="small">discount</Icon>,
    route: "/promo-code/:code",
    component: <PromoCodeEdit />,
  },
  {
    type: "collapse",
    name: "Sliders",
    key: "sliders",
    icon: <Icon fontSize="small">swipe_right</Icon>,
    route: "/sliders",
    component: <Sliders />,
  },
  {
    type: "collapse",
    name: "Sliders",
    key: "sliders",
    icon: <Icon fontSize="small">swipe_right</Icon>,
    route: "/sliders/:id",
    component: <EditSlider />,
  },
  {
    type: "collapse",
    name: "Sliders",
    key: "sliders",
    icon: <Icon fontSize="small">swipe_right</Icon>,
    route: "/sliders/new",
    component: <NewSlider />,
  },

  // Airalo ESIM Orders
  {
    type: "collapse",
    name: "Airalo",
    key: "airalo",
    icon: <Icon fontSize="small">sim_card</Icon>,
    route: "/airalo",
    component: <EsimOrders />,
  },

  // Airlao ESIM Orders

  {
    type: "collapse",
    name: "Manual Bookings",
    key: "manual-bookings",
    icon: <Icon fontSize="small">new_releases</Icon>,
    route: "/manual-bookings",
    component: <ManualBookings />,
  },
  {
    type: "collapse",
    name: "Manual Bookings",
    key: "manual-bookings",
    icon: <Icon fontSize="small">new_releases</Icon>,
    route: "/manual-bookings/visa-orders",
    component: <ManualVisaOrders />,
  },
  {
    type: "collapse",
    name: "Manual Bookings",
    key: "manual-bookings",
    icon: <Icon fontSize="small">new_releases</Icon>,
    route: "/manual-bookings/hotel-bookings-orders",
    component: <ManualHotelBookings />,
  },
  {
    type: "collapse",
    name: "Manual Bookings",
    key: "manual-bookings",
    icon: <Icon fontSize="small">new_releases</Icon>,
    route: "/manual-bookings/flight-orders",
    component: <ManualFlightBookings />,
  },
  {
    type: "collapse",
    name: "Manual Bookings",
    key: "manual-bookings",
    icon: <Icon fontSize="small">new_releases</Icon>,
    route: "/manual-bookings/transportation-orders",
    component: <ManualTransportaionOrders />,
  },
];

export const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Jadwelny Orders",
    key: "jad-orders",
    icon: <Icon fontSize="small">hotel</Icon>,
    route: "/jad-orders",
    component: <RHOrders />,
  },
  // {
  //   type: "collapse",
  //   name: "All Orders",
  //   key: "all-orders",
  //   icon: <Icon fontSize="small">hotel</Icon>,
  //   route: "/all-orders",
  //   component: <RHOrders2 />,
  // },
  {
    type: "collapse",
    name: "Wallet Transactions",
    key: "wallet-transactions",
    icon: <Icon fontSize="small">wallet</Icon>,
    route: "/wallet-transactions",
    component: <Wallet />,
  },
  {
    type: "collapse",
    name: "Points Transactions",
    key: "points-transactions",
    icon: <Icon fontSize="small">attach_money</Icon>,
    route: "/points-transactions",
    component: <Points />,
  },
  {
    type: "collapse",
    name: "Promo Codes",
    key: "promo-code",
    icon: <Icon fontSize="small">discount</Icon>,
    route: "/promo-code",
    component: <PromoCode />,
  },
  {
    type: "collapse",
    name: "Booking Emails",
    key: "emails-booking",
    icon: <Icon fontSize="small">mail</Icon>,
    route: "/emails-booking",
    component: <EmailBooking />,
  },
  {
    type: "collapse",
    name: "Airalo",
    key: "airalo",
    icon: <Icon fontSize="small">sim_card</Icon>,
    route: "/airalo",
    component: <EsimOrders />,
  },
  {
    type: "collapse",
    name: "Countries",
    key: "countries",
    icon: <Icon fontSize="small">public</Icon>,
    route: "/countries",
    component: <Country />,
  },
  {
    type: "collapse",
    name: "Activities",
    key: "activities",
    icon: <Icon fontSize="small">stadium</Icon>,
    route: "/activities",
    component: <Activity />,
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    route: "/userplans",
    component: <Userplans />,
  },
  {
    type: "collapse",
    name: "Main categories",
    key: "main_categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/main_categories",
    component: <MainCategories />,
  },
  {
    type: "collapse",
    name: "Activity categories",
    key: "activity_categories",
    icon: <Icon fontSize="small">segment</Icon>,
    route: "/activity_categories",
    component: <ActivityCategories />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Questionnaire",
    key: "questionnaire",
    icon: <Icon fontSize="small">question_answer</Icon>,
    route: "/questionnaire",
    component: <Questionnaire />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">report</Icon>,
    route: "/reports",
    component: <Questionnaire />,
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "booking emails",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/booking",
    component: <Booking />,
  },
  {
    type: "collapse",
    name: "Sliders",
    key: "sliders",
    icon: <Icon fontSize="small">swipe_right</Icon>,
    route: "/sliders",
    component: <Sliders />,
  },
  {
    type: "collapse",
    name: "Manual Bookings",
    key: "manual-bookings",
    icon: <Icon fontSize="small">new_releases</Icon>,
    route: "/manual-bookings",
    component: <ManualBookings />,
  },
];
